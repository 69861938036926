import { get, post } from "@/utils/request"

// 登录
interface Login {
  username: string;
  password: string;
}
export const userLogin = (data: Login) => post("/user/login", data)

// 获取文章
interface GetArticle {
  title?: string;
  label?: string;
  order?: string;
  pagenum: number;
  pagesize: number;
}
export const getArticle = (data: GetArticle) => get("/article/get_article", data)

// 获取文章
interface GetArticleIdCon {
  id: string;
}
export const getArticleIdCon = (data: GetArticleIdCon) => get("/article/art_detailed", data)

// 删除文章
export const delArticle = (data: GetArticleIdCon) => get("/article/del_article", data)

// 添加文章
interface Article {
  id?: string;
  title: string;
  label: string;
  info: string;
  content: string;
  source: string;
  author: string;
  cover: string;
}
export const addArticle = (data: Article) => post("/article/add_article", data)

// 修改文章
export const updateArticle = (data: Article) => post("/article/update_article", data)

// 获取文章详细
interface GetArtDetailed {
  id: string;
}
export const getArtDetailed = (data: GetArtDetailed) => get("/article/art_detailed", data)

// 获取文章评论
interface GetArtComment {
  artid: string;
  pagenum: number;
  pagesize: number;
}
export const getArtComment = (data: GetArtComment) => get("/article/get_art_comment", data)

// 添加文章评论
interface AddArtComment {
  artid: number;
  username: string;
  content: string;
}
export const addArtComment = (data: AddArtComment) => post("/article/add_art_comment", data)

// 上传文件
export const uploadFiles = (data: any) => post("/files/uploadFiles", data)

// 获取文件
interface GetFiles {
  pagenum: number;
  pagesize: number;
}
export const getFiles = (data: GetFiles) => get("/files/getFiles", data)

// 删除文件
interface DelCoverImg {
  file: string;
  sign?: number;
}
export const delFiles = (data: DelCoverImg) => get("/files/delFiles", data)

// 回复文章评论
interface ReplyArtComment {
  id: number;
  reply: string;
}
export const replyArtComment = (data: ReplyArtComment) => post("/article/reply_art_comment", data)

// 删除文章评论
interface DelArtComment {
  id: number;
}
export const delArtComment = (data: DelArtComment) => get("/article/del_art_comment", data)

// 获取留言
interface GetLeavingMsg {
  pagenum: number;
  pagesize: number;
}
export const getLeavingMsg = (data: GetLeavingMsg) => get("/message/get_leaving_msg", data)

// 添加留言
interface AddLeavingMsg {
  username: string;
  content: string;
}
export const addLeavingMsg = (data: AddLeavingMsg) => post("/message/add_leaving_msg", data)

// 修改留言
export const ReplyLeavingMsg = (data: ReplyArtComment) => post("/message/reply_leaving_msg", data)

// 删除留言
export const delLeavingMsg = (data: DelArtComment) => get("/message/del_leaving_msg", data)

// 浏览数或点赞数
interface Visit {
  artid: string;
  sign?: number;
}
export const addVisit = (data: Visit) => get("/article/visit", data)

// 获取友情链接
export const getLinks = () => get("/links/get_links")

// 修改友情链接
interface Links {
  id?: number;
  title: string;
  info: string;
  logo: string;
  link: string;
}
export const updateLinks = (data: Links) => post("/links/update_links", data)

// 添加友情链接
export const addLinks = (data: Links) => post("/links/add_links", data)

// 删除友情链接
interface DelLinks {
  id: number;
}
export const delLinks = (data: DelLinks) => get("/links/del_links", data)

// 在线工具

// 去水印数据接口
interface RmWatermark {
  url: string;
}
// 去抖音水印
export const rmDouYin = (data: RmWatermark) => get("/tools/douYin", data)
// 去快手水印
export const rmKuaiShou = (data: RmWatermark) => get("/tools/kuaiShou", data)

// 词云
// 上传词云图片
export const uploadCiYunImg = () => post("/tools/uploadCiYunImg")
// 获取词云
interface GetCiYun {
  value: string;
  text: string;
  filename: string;
}
export const getCiYun = (data: GetCiYun) => post("/tools/ciYun", data)
