import axios from 'axios'
import { getCookie } from '@/utils/cookie'
import { message } from 'ant-design-vue';

// export const baseUrl = 'http://127.0.0.1:5000/api/v1'
export const baseUrl = 'https://blog.api.mxtian.cn/api/v1/'

const service: any = axios.create({
  baseURL: baseUrl,
  timeout: 30000
})

//请求拦截
service.interceptors.request.use((config: any): any => {
  const token = getCookie('token')
  const user = getCookie('user')
  if(token) {
    // config.headers.common["Authorization"] = token;
    config.headers.token = token;
    config.headers.user = user;
  }
  return config
}, (err: any) => {
  console.log('请求拦截-err :', err)
  // message
  return Promise.reject(err)
})

// 响应拦截
service.interceptors.response.use((response: any) => {
  // console.log('响应拦截 :', response)
  const data = response.data
  if (data) {
    switch (data.code) {
      case 0:
        return data
      default:
    }
  }
  return data
},
(err: any): any => {
  // console.dir(err)
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        err.message = '请求参数错误'
        break
      case 401:
        err.message = '未授权，请登录'
        break
      case 403:
        err.message = '跨域拒绝访问'
        break
      case 404:
        err.message = `请求地址出错: ${err.response.config.url}`
        break
      case 408:
        err.message = '请求超时'
        break
      case 500:
        err.message = '服务器内部错误'
        break
      case 501:
        err.message = '服务未实现'
        break
      case 502:
        err.message = '网关错误'
        break
      case 503:
        err.message = '服务不可用'
        break
      case 504:
        err.message = '网关超时'
        break
      case 505:
        err.message = 'HTTP版本不受支持'
        break
      default:
    }
  }
  message.error(err.message);
  return Promise.reject(err)
})


export function get (url: string, params = {}, headers = {}) {
  return service({
    methods: 'GET',
    url: url,
    params: params,
    headers: headers,
    emulateJSON: true
  })
}

export function post (url: string, data = {}, headers = {}) {
  return service.post(url, data, headers)
}

export function del (url: string, data = {}, headers = {}) {
  return service.delete(url, data, headers)
}

export function put (url: string, data = {}, headers = {}) {
  return service.put(url, data, headers)
}
